import { FEATURE_FLAGS } from '@yi/core';

const prefix = 'yi.ff.';
export { prefix as featureFlagStorageKeyPrefix };

export type FeatureFlagName = (typeof FEATURE_FLAGS)[keyof typeof FEATURE_FLAGS];

export const makeFeatureFlagLocalStorageKey = (flagName: FeatureFlagName) =>
  `${prefix}${flagName}` as (typeof featureFlagStorageKeys)[keyof typeof featureFlagStorageKeys];

// @TODO directly derive this object from FEATURE_FLAGS, while maintaining type as-is
export const featureFlagStorageKeys = {
  ENTITLEMENTS: `${prefix}${FEATURE_FLAGS.ENTITLEMENTS}` as const,
  ZUORA_CHECKOUT: `${prefix}${FEATURE_FLAGS.ZUORA_CHECKOUT}` as const,
  MIGRATION_ACCOUNT_LOCK: `${prefix}${FEATURE_FLAGS.MIGRATION_ACCOUNT_LOCK}` as const,
  ADYEN: `${prefix}${FEATURE_FLAGS.ADYEN}` as const,
  ADYEN_PAYPAL: `${prefix}${FEATURE_FLAGS.ADYEN_PAYPAL}` as const,
  STRIPE_ACCESS_FIX: `${prefix}${FEATURE_FLAGS.STRIPE_ACCESS_FIX}` as const,
  BRIGHTCOVE_MEDIA_SOURCE: `${prefix}${FEATURE_FLAGS.BRIGHTCOVE_MEDIA_SOURCE}` as const,
  CHECKOUT_V2: `${prefix}${FEATURE_FLAGS.CHECKOUT_V2}` as const,
  SITE_WIDE_SEARCH: `${prefix}${FEATURE_FLAGS.SITE_WIDE_SEARCH}` as const,
  BRIGHTCOVE_TEST_PLAYER: `${prefix}${FEATURE_FLAGS.BRIGHTCOVE_TEST_PLAYER}` as const,
  BRIGHTCOVE_HLS_BYTERANGE: `${prefix}${FEATURE_FLAGS.BRIGHTCOVE_HLS_BYTERANGE}` as const,
  SALES_BANNER: `${prefix}${FEATURE_FLAGS.SALES_BANNER}` as const,
  REDIRECT_TO_CHANGE_PLAN: `${prefix}${FEATURE_FLAGS.REDIRECT_TO_CHANGE_PLAN}` as const,
} as const;

const validated: { [k in keyof typeof FEATURE_FLAGS]: string } = featureFlagStorageKeys;
// @ts-expect-error `unused` error is expected because used only for side effect to validate missing keys
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const used = validated;
